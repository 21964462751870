import Vue from "vue";

/*TITLE*/
document.title = "Les Terrasses de Can Coll | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Les Terrasses de Can Coll";
Vue.prototype.$subtitle = "Promoción en Molins del Rei con amplias estancias y terrazas";

/*INTRO*/
Vue.prototype.$promoter = "Les Terrasses de Can Coll";
Vue.prototype.$introSubtitle = "Promoción en Molins del Rei con amplias estancias y terrazas";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-opc.-1-viena-eisenhut--20230127110112.jpg";
Vue.prototype.$image_kitchen = "3-kitchen--20221124091509.jpeg";
Vue.prototype.$image_bath1 = "2-bathroom1--20221124091509.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091509.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-opc.-1-viena-eisenhut--20230127110112.jpg",
  },
  {
    src: "salon-viena-sandig--20230127110124.jpg",
  },
  {
    src: "salon-viena-worner--20230127110136.jpg",
  },
  {
    src: "salon-opc.-3-forest-maple--20230127110144.jpg",
  },
  {
    src: "salon-opc.-2-bottega-acero--20230127110152.jpg",
  },
  {
    src: "salon-bottega-caliza--20230127110102.jpg",
  },
  {
    src: "salon-bottega-white--20230127110110.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "3-kitchen--20221124091509.jpeg",
  },
  {
    src: "4-kitchen--20221124091509.jpeg",
  },
  {
    src: "cocina-opc.-5-pavimento-forest-maple-frentes-grafitti-encimera-xtone-bottega-caliza--20230127110120.jpg",
  },
  {
    src: "cocina-opc.-1-pavimento-bottega-acero-con-viena-eisenhut-frentes-blanco-nubol-encimera-xtone-bottega-antracita--20230127110150.jpg",
  },
  {
    src: "cocina-viena-sandig-bottega-caliza--20230127110102.jpg",
  },
  {
    src: "cocina-viena-worner-bottega-white--20230127110117.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "2-bathroom1--20221124091509.jpeg",
  },
  {
    src: "3-bathroom1--20221124091509.jpeg",
  },
  {
    src: "4-bathroom1--20221124091509.jpeg",
  },
  {
    src: "5-bathroom1--20221124091509.jpeg",
  },
  {
    src: "6-bathroom1--20221124091509.jpeg",
  },
  {
    src: "7-bathroom1--20221124091509.jpeg",
  },
  {
    src: "8-bathroom1--20221124091509.jpeg",
  },
  {
    src: "9-bathroom1--20221124091509.jpeg",
  },
  {
    src: "10-bathroom1--20221124091509.jpeg",
  },
  {
    src: "11-bathroom1--20221124091509.jpeg",
  },
  {
    src: "12-bathroom1--20221124091509.jpeg",
  },
  {
    src: "banop-opc.-6-pavimento-bottega-white-revestimiento-base-bottega-white-con-plato-de-ducha-mueble-roble-ceniza--20230127110143.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091509.jpeg",
  },
  {
    src: "1-bathroom2--20221124091509.jpeg",
  },
  {
    src: "2-bathroom2--20221124091509.jpeg",
  },
  {
    src: "3-bathroom2--20221124091509.jpeg",
  },
  {
    src: "4-bathroom2--20221124091509.jpeg",
  },
  {
    src: "5-bathroom2--20221124091509.jpeg",
  },
  {
    src: "6-bathroom2--20221124091509.jpeg",
  },
  {
    src: "7-bathroom2--20221124091509.jpeg",
  },
  {
    src: "8-bathroom2--20221124091509.jpeg",
  },
  {
    src: "9-bathroom2--20221124091509.jpeg",
  },
  {
    src: "10-bathroom2--20221124091509.jpeg",
  },
  {
    src: "11-bathroom2--20221124091509.jpeg",
  },
  {
    src: "12-bathroom2--20221124091509.jpeg",
  },
  {
    src: "13-bathroom2--20221124091509.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LAS_TERRAZAS_DE_CAN_COLL/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/aAGuuIiGmtA";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=av%20barcelona%20118%20molins%20de%20rei%20",
    text: "Av. Barcelona 118 (Molins de Rei)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=av%20barcelona%20113%20molins%20de%20rei",
    text: "Av. Barcelona 113. Molins de Rei",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:648746202",
    text: "648746202",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@lesterrassesdecancoll.com",
    text: "info@lesterrassesdecancoll.com",
  },
];
